<template>
  <div>
    <v-toolbar
      flat
      color="red darken-3"
    >
      <v-toolbar-title class="hidden-sm-and-down">
        {{ $t('ebay_documentation.label') }}
      </v-toolbar-title>
      <v-divider
        class="mx-5 hidden-sm-and-down"
        inset
        vertical
      />
    </v-toolbar>

    <div>
      <div class="col-12 pl-5 mt-3">
        <h3>{{ $t('ebay_documentation.title') }}</h3>
        <img
          src="@/assets/leko/ebay_leko_1.png"
          alt="Inventory Image"
          height="160px"
          class="mr-2 mt-2"
          @mousemove="zoomIn"
          @mouseleave="zoomOut"
        >
      </div>


      <ol class="pl-5 mt-2 pr-2">
        <li>
          <h3>{{ $t('introduction.ebay_leko.options') }}</h3>
          <ul>
            <li> {{ $t('introduction.ebay_leko.options_description') }}</li>
          </ul>
        </li>
        <div class="mt-3">
          <img
            src="@/assets/leko/ebay_leko_2.png"
            alt="Inventory Image"
            height="180px"
            @mousemove="zoomIn"
            @mouseleave="zoomOut"
          >
        </div>
        <li>
          <h3>{{ $t('introduction.ebay_leko.check_ebay') }}</h3>
          <ul>
            <li> {{ $t('introduction.ebay_leko.check_ebay_description') }}</li>
          </ul>
        </li>
        <div class="mt-3">
          <img
            src="@/assets/leko/ebay_leko_3.png"
            alt="Inventory Image"
            height="180px"
            @mousemove="zoomIn"
            @mouseleave="zoomOut"
          >
        </div>

        <li>
          <h3>{{ $t('introduction.ebay_leko.edit_options') }}</h3>
          <ul>
            <li> {{ $t('introduction.ebay_leko.edit_options_description') }}</li>
          </ul>
        </li>
        <div class="mt-3">
          <img
            src="@/assets/leko/ebay_leko_5.png"
            alt="Inventory Image"
            height="180px"
            @mousemove="zoomIn"
            @mouseleave="zoomOut"
          >
        </div>
        <li>
          <h3>{{ $t('introduction.ebay_leko.delete_options') }}</h3>
          <ul>
            <li> {{ $t('introduction.ebay_leko.delete_options_description') }}</li>
          </ul>
        </li>
        <div class="mt-3">
          <img
            src="@/assets/leko/ebay_leko_4.png"
            alt="Inventory Image"
            height="150px"
            @mousemove="zoomIn"
            @mouseleave="zoomOut"
          >
        </div>
        <li>
          <h3>{{ $t('ebay_documentation.ebay_conditions') }}</h3>
          <h4> {{ $t('ebay_documentation.ebay_conditions_description') }}</h4>
          <ul>
            <li>   {{ $t('ebay_documentation.ebay_condition_0') }}</li>
            <li>   {{ $t('ebay_documentation.ebay_condition_1') }}</li>
            <li>   {{ $t('ebay_documentation.ebay_condition_2') }}</li>
            <li>   {{ $t('ebay_documentation.ebay_condition_3') }}</li>
            <li>   {{ $t('ebay_documentation.ebay_condition_4') }}</li>
            <li>   {{ $t('ebay_documentation.ebay_condition_5') }}</li>
            <li>  {{ $t('ebay_documentation.ebay_condition_6') }}</li>
          </ul>
        </li>
      </ol>
      <div class="vue-mastery ml-3 mr-3">
        <a
          href="https://www.youtube.com/watch?v=Kj4DjSGOAkE"
          target="_blank"
          rel="sponsored noopener"
          title=""
        >{{ $t('ebay_documentation.watch_video') }} </a>
      </div>
      <div class="vue-mastery ml-3 mr-3">
        <a
          href="https://www.youtube.com/watch?v=Z5IAxGZC_Lo"
          target="_blank"
          rel="sponsored noopener"
          title=""
        >{{ $t('ebay_documentation.watch_video_success') }}</a>
      </div>
      <div class="text-center mt-2">
        <img
          src="@/assets/leko/ebayLeko.png"
          alt="Inventory Image"
          height="420px"
        >
      </div>
    </div>
  </div>
</template>

<script>
import zoom from '../utils/zoom';

export default {
  components: {
  },
  data() {
    return {
      rotation: 0,

    };
  },
  methods: {
    zoomIn(event) {
      zoom.zoomIn(2.4, event, this.rotation);
      const img = event.target;

      img.classList.add('zoomed');
    },
    zoomOut(event) {
      zoom.zoomOut(event, this.rotation);
      const img = event.target;

      img.classList.remove('zoomed');
    },
  },
};
</script>
<style scoped>
.zoomed {
  position: relative;
  z-index: 9999;
  transform: scale(1.5);
  transition: transform 0.2s ease-in-out;
}
body {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  margin: 20px;
}

h2 {
  color: #333;
}

ol, ul {
  margin-top: 0;
}

li {
  margin-bottom: 10px;
}
a {
  text-decoration: none;
  color: #304455;
}
em {
  color: #4f5959;
}
p {
  word-spacing: 0.05em;
}
a.button,
input.button {
  padding: 0.75em 2em;
  border-radius: 2em;
  display: inline-block;
  color: #fff;
  background-color: #4fc08d;
  transition: all 0.15s ease;
  box-sizing: border-box;
  border: 1px solid #4fc08d;
}
a.button.white,
input.button.white {
  background-color: #fff;
  /* color: #42b983; */
}
a.button.gray,
input.button.gray {
  background-color: #f6f6f6;
  color: #4f5959;
  border-color: #f6f6f6;
}
#demo,
.demo,
.content .demo {
  border: 1px solid #eee;
  border-radius: 2px;
  padding: 25px 35px;
  margin-top: 1em;
  margin-bottom: 40px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow-x: auto;
}
#demo h1,
.demo h1,
.content .demo h1 {
  margin: 0 0 0.5em;
  font-size: 1.8em;
}
#demo h2,
.demo h2,
.content .demo h2 {
  padding: 0;
  border: none;
}
#demo h2,
.demo h2,
.content .demo h2,
#demo h3,
.demo h3,
.content .demo h3,
#demo h4,
.demo h4,
.content .demo h4,
#demo h5,
.demo h5,
.content .demo h5,
#demo h6,
.demo h6,
.content .demo h6 {
  margin: 1em 0;
}
#demo ul,
.demo ul,
.content .demo ul,
#demo ul:first-child,
.demo ul:first-child,
.content .demo ul:first-child,
#demo ul:last-child,
.demo ul:last-child,
.content .demo ul:last-child,
#demo li,
.demo li,
.content .demo li {
  color: #304455;
}
#demo li.done,
.demo li.done,
.content .demo li.done {
  color: #4f5959;
  text-decoration: line-through;
}
#demo p,
.demo p,
.content .demo p {
  margin: 0 !important;
  padding: 0 !important;
}
#demo textarea,
.demo textarea,
.content .demo textarea {
  width: 100%;
  resize: vertical;
}
#demo > :first-child,
.demo > :first-child,
.content .demo > :first-child {
  margin-top: 0;
}
#demo > :last-child,
.demo > :last-child,
.content .demo > :last-child {
  margin-bottom: 0;
}
ul#demo li,
ul.demo li {
  margin-left: 1.5em;
}
@media screen and (max-width: 900px) {
  #demo,
  .demo {
    margin-left: 0;
  }
}
.content.guide[class*="migration"] h2 > sup,
.content.guide[class*="migration"] h3 > sup {
  margin-left: 0.3em;
  color: #b9465c;
}
.content.guide[class*="migration"] .upgrade-path {
  margin-top: 2em;
  padding: 2em;
  background: rgba(73,195,140,0.1);
  border-radius: 2px;
}
.content.guide[class*="migration"] .upgrade-path > h4 {
  margin-top: 0;
}
.content.guide[class*="migration"] .upgrade-path > p:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}
.sidebar {
  position: absolute;
  top: 61px;
  left: 0;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
}
.sidebar h2 {
  margin-top: 0.2em;
}
.sidebar ul {
  list-style-type: none;
  margin: 0;
  line-height: 1.5em;
  padding-left: 1em;
}
.sidebar li {
  margin-top: 0.5em;
}
.sidebar .sidebar-inner {
  width: 260px;
  padding: 35px 0px 60px 20px;
}
.sidebar .version-select {
  vertical-align: middle;
  margin-left: 5px;
}
.sidebar .menu-root {
  padding-left: 0;
}
.sidebar .sidebar-link {
  color: #4f5959;
}
.sidebar .sidebar-link.current {
  font-weight: 600;
  color: #42b983;
}
.sidebar .sidebar-link.new:after {
  content: "NEW";
  display: inline-block;
  font-size: 10px;
  font-weight: 600;
  color: #fff;
  background-color: #42b983;
  line-height: 14px;
  padding: 0 4px;
  border-radius: 3px;
  margin-left: 5px;
  vertical-align: middle;
  position: relative;
  top: -1px;
}
.sidebar .sidebar-link:hover {
  border-bottom: 2px solid #42b983;
}
@media screen and (max-width: 900px) {
  .sidebar {
    position: fixed;
    background-color: #f9f9f9;
    height: 100%;
    top: 40px;
    left: 0;
    box-shadow: 0 0 10px rgba(0,0,0,0.2);
    transition: all 0.4s cubic-bezier(0.4, 0, 0, 1);
    -webkit-transform: translate(-280px, 0);
    transform: translate(-280px, 0);
  }
  .sidebar .sidebar-inner {
    padding: 60px 10px 30px 20px;
    box-sizing: border-box;
  }
  .sidebar .sidebar-inner-index {
    padding: 10px 10px 10px 20px;
  }
  .sidebar.open {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}
.modal {
  box-sizing: border-box;
  display: none;
  position: fixed;
  width: 75%;
  height: auto;
  padding: 0.5em;
  background-color: #f9f9f9;
  box-shadow: 0 0 10px rgba(0,0,0,0.2);
}
.modal.open {
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 40;
}
.modal-text {
  margin-bottom: 0.5em;
  text-align: center;
}
.modal-text > a {
  color: #42b983;
  font-weight: 600;
}
@media screen and (max-width: 400px) {
  .modal {
    width: 98%;
  }
}
.vue-mastery {
  background-color: #e7ecf3;
  padding: 1em 1.25em;
  border-radius: 2px;
  color: #486491;
  position: relative;
  margin-top: 1em;
}
.vue-mastery a {
  color: #486491 !important;
  position: relative;
  padding-left: 36px;
}
.vue-mastery a:before {
  content: '';
  position: absolute;
  display: block;
  width: 30px;
  height: 30px;
  top: -5px;
  left: -4px;
  border-radius: 50%;
  background-color: #73abfe;
}
.vue-mastery a:after {
  content: '';
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  top: 5px;
  left: 8px;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 8px solid #fff;
}
.content {
  position: relative;
  padding: 35px 0;
  max-width: 700px;
  margin: 0 auto;
  padding-left: 50px;
}
.content a.button {
  font-size: 0.9em;
  color: #fff;
  margin: 0.2em 0;
  width: 200px;
  text-align: center;
  padding: 12px 24px;
  display: inline-block;
  vertical-align: middle;
}
.content img {
  max-width: 100%;
}
.content span.info {
  font-size: 0.85em;
  display: inline-block;
  vertical-align: middle;
  width: 280px;
  margin-left: 20px;
}
.content h1 {
  margin: 0 0 1em;
}
.content h2,
.content h3 {
  pointer-events: none;
}
.content h2 a,
.content h3 a,
.content h2 button,
.content h3 button {
  pointer-events: auto;
  color: #273849;
}
.content h2:before,
.content h3:before {
  content: "";
  display: block;
  margin-top: -96px;
  height: 96px;
  visibility: hidden;
}
.content h2 {
  margin: 45px 0 0.8em;
  padding-bottom: 0.7em;
  border-bottom: 1px solid #ddd;
}
.content h3 {
  line-height: 1.2;
  position: relative;
}
.content h3 > a:before {
  content: "#";
  color: #42b983;
  position: absolute;
  left: -0.7em;
  margin-top: -0.05em;
  padding-right: 0.5em;
  font-size: 1.2em;
  line-height: 1;
  font-weight: bold;
}
.content p,
.content ul,
.content ol {
  line-height: 1.6em;
  margin: 1.2em 0 -1.2em;
  padding-bottom: 1.2em;
  position: relative;
  z-index: 1;
}
.content ul,
.content ol {
  padding-left: 1.5em;
  position: inherit;
}
.content ul ul,
.content ol ul,
.content ul ol,
.content ol ol {
  margin: 0;
}
.content a {
  color: #42b983;
  font-weight: 600;
}
.content iframe {
  margin: 1em 0;
}
.content p.tip,
.content p.success {
  padding: 12px 24px 12px 30px;
  margin: 2em 0;
  border-left-width: 4px;
  border-left-style: solid;
  background-color: #f8f8f8;
  position: relative;
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
}
.content p.tip:before,
.content p.success:before {
  position: absolute;
  top: 14px;
  left: -12px;
  color: #fff;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  text-align: center;
  line-height: 20px;
  font-weight: bold;
  font-family: "Dosis", "Source Sans Pro", "Helvetica Neue", Arial, sans-serif;
  font-size: 14px;
}
.content p.tip em,
.content p.success em {
  color: #304455;
}
.content p.tip {
  border-left-color: #f66;
}
.content p.tip:before {
  content: "!";
  background-color: #f66;
}
.content p.success {
  border-left-color: #42b983;
}
.content p.success:before {
  content: "\f00c";
  font-family: FontAwesome;
  background-color: #42b983;
}
.content p.warning {
  border-left-color: #ffa500;
}
.content p.warning:before {
  background-color: #ffa500;
}
.content p.v3-warning {
  margin-top: 0;
}

@media screen and (max-width: 1300px) {
  .content.with-sidebar {
    margin-left: 290px;
  }
  #ad {
    z-index: 1;
    position: relative;
    padding: 0;
    bottom: 0;
    right: 0;
    float: right;
    padding: 0 0 20px 30px;
  }
}
@media screen and (max-width: 900px) {
  body {
    -webkit-text-size-adjust: none;
    font-size: 14px;
  }
  .content {
    padding-left: 0;
  }
  .content.with-sidebar {
    margin: auto;
  }
  .content h2:before,
  .content h3:before {
    content: "";
    display: block;
    margin-top: -75px;
    height: 75px;
    visibility: hidden;
  }
}
@media screen and (max-width: 560px) {
  iframe {
    margin: 0 !important;
  }
}
>>> .menu-sub a {
  color: #425364!important;
}

</style>
